<template>
  <div class="homeComponent">
    <div :class="`bars ${isMenuOpen ? 'whitespace' : ''}`">
      <agency-sidebar v-if="isLoaded"></agency-sidebar>
      <div
        :class="{ active: isMenuOpen }"
        class="topbar"
        :style="'margin-top: ' + topbarPosition + 'px;'"
      >
        <div
          class="
            top
            d-flex
            justify-content-between
            align-items-center
            md-flex-row-reverse
          "
        >
          <div class="backBtn">
            <i
              style="font-size: 18px"
              type="button"
              @click="backBtn"
              class="flaticon-left-arrow-1 white-text md-d-none"
            ></i>
          </div>
          <a
            @click="toggleMenu('')"
            class="toggleMenu"
            style="color: white"
            ref="sidebarBtn"
          >
            <i class="flaticon-menu"></i>
          </a>
        </div>
        <div class="header">
          <div class="container">
            <ul>
              <li class="green-text">Home</li>
              <li
                class="currentPage capitalize"
                v-for="(name, index) in this.headers"
                :key="index"
              >
                {{ name.replace(/[\-]/g, " ") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoaded" class="main-content" :class="{ collapse: isMenuOpen }">
      <transition name="slide">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { profile as profileAPI } from "@/api";

let glob;

export default {
  components: {
    AgencySidebar: () => import("@/components/Sidebar/LawyerSidebar")
  },
  name: "home",
  data() {
    return {
      lastMenu: "",
      headers: [],
      topbarPosition: 0,
      lastOffset: 0,

      isLoaded: false
    };
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.getSidebarState;
    }
  },
  watch: {
    $route: {
      handler: function (newVal) {
        glob.topbarPosition = 0;
        glob.lastOffset = 0;

        glob.getRouting(newVal);
        if (newVal.path == "/order") {
          document.querySelector(".backBtn i").classList.add("d-none");
        } else {
          document.querySelector(".backBtn i").classList.remove("d-none");
        }
      },
      deep: true
    }
  },
  mounted() {
    glob = this;

    this.init();

    this.lastOffset = window.pageYOffset;
    this.initEvents();

    this.getRouting(this.$route);
    if (this.$route.path == "/property-listing") {
      document.querySelector(".backBtn i").classList.add("d-none");
    }
  },
  methods: {
    // ============================ INITIALIZATION =============================
    async init() {
      await this.initUserData();
      // await this.initPermission();
      this.initMenuState();
      this.isLoaded = true;
    },
    async initUserData() {
      try {
        this.$store.commit("setIsLoading", true);
        const userData = await profileAPI.getProfile();

        this.$auth.user(userData);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    initMenuState() {
      window.innerWidth >= 768
        ? this.$store.commit("openSidebar")
        : this.$store.commit("closeSidebar");
    },
    async initPermission() {
      await this.setPermission();
    },
    initEvents() {
      document.querySelector("html").addEventListener("click", (e) => {
        const clicked = e.path.slice(0, -5);
        let isMenuClosing = true;

        for (var x of clicked) {
          if (
            x.classList.contains("fd-sidebar") ||
            x.classList.contains("toggleMenu")
          ) {
            isMenuClosing = false;
            break;
          }
        }

        if (window.innerWidth <= 768) {
          if (isMenuClosing) {
            this.closeMenu();
          }
        }
      });

      if (window.innerWidth <= 768) {
        window.addEventListener("scroll", this.handleScroll);
      } else {
        window.removeEventListener("scroll", this.handleScroll);
      }

      window.onresize = () => {
        if (window.innerWidth <= 768) {
          window.addEventListener("scroll", this.handleScroll);
        } else {
          window.removeEventListener("scroll", this.handleScroll);
          glob.topbarPosition = 0;
          glob.lastOffset = 0;
        }
      };
    },
    // ============================ HELPER METHODS =============================
    getRouting(route) {
      var str = route.path.split("/").slice(1);
      var isHome = false;

      for (var x of str) {
        if (x.includes("home")) {
          isHome = true;
        }
      }

      for (var count = 0; count < str.length; count++) {
        // eslint-disable-next-line no-useless-escape
        str[count] = str[count].replace(/\#\d{1,}/, "");
      }

      if (!isHome) {
        this.headers = str;
      } else {
        this.headers = [];
      }
    },
    toggleMenu() {
      this.$store.commit("toggleSidebar");
    },
    closeMenu() {
      this.$store.commit("closeSidebar");
    },
    backBtn() {
      window.history.back();
    },

    handleScroll() {
      let lastOffset = glob.lastOffset;
      let currentOffset = window.pageYOffset;
      let difference = currentOffset - lastOffset;

      if (currentOffset > 0) {
        if (glob.topbarPosition - difference >= 0) {
          glob.topbarPosition = 0;
        } else if (glob.topbarPosition - difference <= -67) {
          glob.topbarPosition = -67;
        } else {
          glob.topbarPosition = glob.topbarPosition - difference;
        }

        glob.lastOffset = currentOffset;
      } else if (currentOffset <= 0) {
        glob.topbarPosition = 0;

        glob.lastOffset = 0;
      }
    },

    async setPermission() {
      this.$store.commit("setIsLoading", true);
      await this.$store.dispatch("manageAccount/setAccountPermission");
      this.$store.commit("setIsLoading", false);
    }
  }
};
</script>

<style lang="scss">
.homeComponent {
  .bars {
    @include flex(row, flex-start, flex-start);
    position: relative;
    z-index: 100;

    .topbar {
      &.active {
        width: calc(100% - 251px);
      }
    }

    &.whitespace {
      .sidebar {
        .bottom {
          overflow-x: auto;
          ul {
            li {
              a {
                white-space: normal;
              }
            }
          }
        }
      }
    }

    .topbar {
      @include flex(column, flex-start, flex-start);
      transition: all 0.5s, margin-top 0s;
      border-bottom: 1px solid #eee;
      width: calc(100% - 46px);
      position: fixed;
      height: auto;
      right: 0;
      top: 0;
      .top {
        background-color: $color-10;
        padding: 0 15px;
        height: 66px;
        width: 100%;
        .backBtn {
          color: $color-main;
          padding: 0 15px;
          i {
            transition: 0.3s;
            cursor: pointer;
          }
        }
        .toggleMenu {
          @include flex(row, center, center);
          cursor: pointer;
          height: 100%;
          i {
            @include flex(row, center, center);
            padding: 0 15px;
            height: 100%;
            color: $color-main;
          }
        }
      }
      .header {
        width: 100%;
        position: relative;
        background-color: white;
        margin: -1px 0 0;
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba($color-13, 0.1);
        }
        ul {
          display: flex;
          position: relative;
          font-weight: 300;
          padding: 16px 0;
          color: #999;
          z-index: 1;
          margin: 0;
          li {
            list-style: none;
            white-space: nowrap;
            color: $color-secondary;
            text-transform: capitalize;
            &.currentPage {
              &:before {
                display: inline-block;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                color: $color-main;
                content: "/";
              }
            }
          }
        }
      }
    }
  }

  .main-content {
    &.collapse {
      padding: 135px 0 28px 251px;
    }
    padding: 135px 0 32px 46px;
    // overflow-x: hidden;
    transition: 0.5s;
    display: block;
    width: 100%;

    .animatedBox {
      transition: 0.5s;
      width: 100%;
    }

    .slide-enter-active,
    .slide-leave-active {
      transition: 0.5s;
    }
    .slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
      position: absolute;
      transform: translateY(15%);
    }
  }

  .floatingActionButton {
    background-color: orange;
    border-radius: 50%;
    position: fixed;
    padding: 14px;
    bottom: 8px;
    right: 8px;
    i {
      font-size: 20px;
      color: white;
    }
  }

  @media #{$breakpoint-down-sm} {
    .bars {
      .topbar {
        &.active {
          left: 251px;
          .backBtn {
            i {
              opacity: 0;
              visibility: hidden;
            }
          }
          .logo {
            opacity: 0;
            visibility: hidden;
            position: absolute;
          }
        }
      }
      .topbar {
        left: 0;
        width: 100%;
        .header {
          display: none;
        }
      }
    }
    .main-content {
      margin: 82px 0 0 0;
      position: relative;
      padding: 0;
      &.collapse {
        margin: 82px 0 32px 251px;
        padding: 0;
      }
    }
  }

  @media #{$breakpoint-down-xs} {
    .bars {
      & .main-content {
        margin: 66px 0 0 0;
        &.collapse {
          margin: 66px 0 32px 239px;
          padding: 0;
        }
      }
    }
  }
}
</style>
